var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    {
      staticClass: "item",
      attrs: { effect: "dark", content: _vm._numberToWords(), placement: "top" }
    },
    [
      _c(
        "el-button",
        { attrs: { size: "mini" } },
        [
          _c("svg-icon", { class: _vm.size, attrs: { name: "inr" } }),
          _vm._v(" " + _vm._s(_vm.getFormattedNumber()) + " ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }